<template>
	<div class="c-subject-tab-list">
		<!-- Desktop -->
		<div class="<1024:hidden">
			<!-- Tabs -->
			<div class="flex bg-black" role="tablist">
				<button
					v-for="(item, index) in items.slice(0, 5)"
					:id="`c-subject-tab-list__tab-${item.id}`"
					:key="`tab-${index}`"
					role="tab"
					class="
						c-subject-tab-list__tab
						group
						w-1/4
						text-left
						px-xl
						py-md
					"
					:class="{
						'bg-background text-black': activeTab === item.id,
						'bg-black text-white': activeTab !== item.id,
					}"
					:aria-selected="activeTab === item.id ? 'true' : 'false'"
					:aria-controls="`c-subject-tab-list__content-panel-${item.id}`"
					:tabindex="activeTab === item.id ? null : '-1'"
					@keyup.left="() => tabsNavigateBy(-1)"
					@keyup.right="() => tabsNavigateBy(1)"
					@click="setActiveTab(item.id)"
				>
					<span
						class="
							inline-flex
							flex-col
							justify-start
							items-start
							h-full
						"
					>
						<div class="w-xl h-xl flex-shrink-0">
							<UmbracoSvg
								class="w-full h-full"
								:svg="item.icon"
							/>
						</div>
						<div
							:class="[
								'w-full transition-transform duration-300 ease-smooth-out mt-sm',
								'font-darker-grotesque font-semibold text-subject-tab',
								{
									'transform group-hover:translate-x-xs':
										activeTab !== item.id,
								},
							]"
							v-html="item.title"
						></div>
					</span>
				</button>
			</div>

			<!-- Content -->
			<div class="bg-background overflow-hidden">
				<div
					:style="`height: ${
						contentHeight != null ? contentHeight + 'px' : 'auto'
					}`"
					class="relative duration-200 ease-smooth-out"
				>
					<TransitionExt
						is-group
						name="fade-in-out"
						duration="200"
						@enter="updateContentHeight"
					>
						<div
							v-for="(item, i) in items"
							v-show="item.id === activeTab"
							:id="`c-subject-tab-list__content-panel-${item.id}`"
							:key="`tablist-${i}`"
							:ref="`content-${item.id}`"
							:aria-hidden="item.id !== activeTab"
							:aria-labelledby="`c-subject-tab-list__tab-${item.id}`"
							role="tabpanel"
							class="top-0 left-0 w-full pt-2xl pb-3xl px-xl"
							:class="[
								item.id === activeTab ? 'relative' : 'absolute',
							]"
						>
							<div class="grid grid-cols-4 gap-x-3xl gap-y-xl">
								<LinkTile
									v-for="(child, j) in item.children"
									:key="`link-${j}`"
									:to="child.url"
									:target="
										child.hasExternalOutboundRedirect
											? '_blank'
											: child.target
									"
									:aria-labelledby="`subject-${i}-title-${j}`"
									:aria-describedby="
										child.teaser
											? `subject-${i}-description-${j}`
											: null
									"
									class="group"
								>
									<!-- Title -->
									<div
										class="
											w-full
											font-darker-grotesque font-semibold
											text-subject-link
											transition-transform
											duration-500
											ease-smooth-out
											transform
											group-hover:translate-x-8
										"
									>
										<!-- Icon -->
										<div
											class="
												inline-flex
												justify-center
												items-center
												w-24
												h-24
												-mt-14
												-mb-10
												mr-4
												bg-black
												text-white
												rounded-full
												align-middle
												transition-transform
												duration-500
												ease-smooth-out
												transform
												group-hover:-translate-x-8
											"
										>
											<SvgArrowStout class="w-12" />
										</div>

										<!-- Text -->
										<HeadingX
											:id="`subject-${i}-title-${j}`"
											class="inline"
											v-html="child.title"
										></HeadingX>
									</div>

									<!-- Teaser -->
									<p
										v-if="child.teaser"
										:id="`subject-${i}-description-${j}`"
										class="text-teaser-sm mt-sm"
										v-text="child.teaser"
									></p>
								</LinkTile>
							</div>
						</div>
					</TransitionExt>
				</div>
			</div>
		</div>

		<!-- Mobile -->
		<AccordionGroup
			ref="mobileAccordionGroup"
			class="overflow-hidden >=1024:hidden"
			:max-one-open="true"
			:min-one-open="true"
		>
			<template v-for="(item, index) in items">
				<!-- Headers -->
				<AccordionHeader
					:id="
						(item.id && `header-id${item.id}`) ||
						item.key ||
						`header-${_uid}-${index}`
					"
					:key="`header-${_uid}-${index}`"
					:aria-controls="`panel-${_uid}-${index}`"
					:class="[
						'relative flex justify-between items-center',
						'px-xl w-full group transition-colors duration-300',
						{
							'bg-background text-black': panelsOpen[index],
							'bg-black text-white': !panelsOpen[index],
						},
					]"
				>
					<div
						class="flex justify-between flex-1 max-w-full py-lg"
						:class="[
							{
								'border-t-2 -mt-2': index !== 0,
								'border-text':
									!panelsOpen[index] &&
									!panelsOpen[index - 1],
								'border-transparent': !(
									!panelsOpen[index] && !panelsOpen[index - 1]
								),
							},
						]"
					>
						<!-- Icon -->
						<span
							class="
								flex-shrink-0
								inline-flex
								justify-center
								items-center
								w-24
								h-24
								mr-lg
							"
						>
							<div class="w-2xl h-2xl flex-shrink-0">
								<UmbracoSvg
									class="w-full h-full"
									:svg="item.icon"
								/>
							</div>
						</span>

						<!-- Title -->
						<div
							:class="[
								'transform pr-lg mr-auto',
								'font-darker-grotesque font-semibold text-subject-tab',
								'transition-transform duration-500 ease-smooth-out',
								{
									'group-hover:translate-x-xs':
										!panelsOpen[index],
								},
							]"
							v-html="item.title"
						></div>

						<!-- Caret -->
						<SvgCaret
							:class="[
								'w-20 transform flex-shrink-0',
								'transition-all duration-300',
								{
									'-rotate-180 text-black': panelsOpen[index],
									'text-white': !panelsOpen[index],
								},
							]"
						/>
					</div>
				</AccordionHeader>

				<!-- Panels -->
				<AccordionPanel
					:id="`panel-${_uid}-${index}`"
					:ref="`panel-${index}`"
					:key="`panel-${_uid}-${index}`"
					:transition="{ name: 't-subject-tab-list__panel' }"
					@change="(event) => onPanelChange(event, index)"
				>
					<div
						:class="[
							'w-full flex flex-col items-start gap-lg',
							'px-xl py-lg transition-colors duration-300',
							{
								'bg-background text-black': panelsOpen[index],
								'bg-black text-white': !panelsOpen[index],
							},
						]"
					>
						<NuxtLinkExt
							v-for="(child, subindex) in item.children"
							:key="subindex"
							:to="child.url"
							:target="child.target"
							class="group inline-block"
						>
							<div
								class="
									inline-flex
									items-center
									w-full
									font-darker-grotesque font-semibold
									text-subject-link
									transition-transform
									duration-500
									ease-smooth-out
									transform
									group-hover:translate-x-8
								"
							>
								<!-- Icon -->
								<span
									class="
										flex-shrink-0
										inline-flex
										justify-center
										items-center
										w-24
										h-24
										mr-lg
										bg-black
										text-white
										rounded-full
										align-middle
										transition-transform
										duration-500
										ease-smooth-out
										transform
										group-hover:-translate-x-8
									"
								>
									<SvgArrowStout class="w-12" />
								</span>

								<!-- Text -->
								<span v-html="child.title"></span>
							</div>
						</NuxtLinkExt>
					</div>
				</AccordionPanel>
			</template>
		</AccordionGroup>
	</div>
</template>

<script>
import UmbracoSvg from '~/citi-baseline/components/UmbracoSvg';
import LinkTile from '~/citi-baseline/components/LinkTile';
import HeadingX from '~/citi-baseline/components/HeadingX';
import {
	AccordionGroup,
	AccordionHeader,
	AccordionPanel,
} from '~/citi-baseline/components/Accordion';
import SvgArrowStout from '~/assets/svgs/icon-arrow-stout.svg?inline';
import SvgCaret from '~/assets/svgs/icon-caret.svg?inline';

export default {
	name: 'SubjectTabList',

	components: {
		UmbracoSvg,
		LinkTile,
		HeadingX,
		AccordionGroup,
		AccordionHeader,
		AccordionPanel,
		SvgArrowStout,
		SvgCaret,
	},

	inheritAttrs: false,

	props: {
		items: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			// Desktop
			activeTab: null,
			contentHeight: undefined,

			// Mobile (accordion)
			panelsOpen: this.items.map(() => false),
			isGroupOpen: false,
		};
	},

	watch: {
		/* Sync desktop to mobile */
		activeTab: {
			handler() {
				if (this.$refs?.mobileAccordionGroup) {
					let index = 0;
					this.items.forEach((e, n) => {
						if (e.id === this.activeTab) {
							index = n;
						}
					});
					this.openPanel(index);

					/**
					 * Update url query to contain
					 * new subject-id.
					 */
					const path = this.$route.path;
					const newPath = `${path}?subjectid=${this.activeTab}`;
					this.$router.replace({ path: newPath });
				}
			},
			immediate: true,
		},
		/* Sync mobile to desktop */
		panelsOpen: {
			handler(panelsOpen) {
				const index = panelsOpen.findIndex((e) => e === true) || 0;
				this.activeTab = this.items[index]?.id;
			},
			immediate: true,
		},
	},

	created() {
		const { query } = this.$route;
		this.activeTab = this.items.find((e) => `${e.id}` === query?.subjectid)
			? parseInt(query.subjectid)
			: this.items?.[0]?.id;
	},

	mounted() {
		window.addEventListener('resize', this.updateContentHeight);

		/**
		 * Opens the correct tab on mobile
		 * based on subject id given in
		 * url query.
		 * */
		if (this.$refs?.mobileAccordionGroup) {
			let index = 0;
			this.items.forEach((e, n) => {
				if (e.id === this.activeTab) {
					index = n;
				}
			});

			this.openPanel(index);
		}

		this.updateContentHeight();
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.updateContentHeight);
	},

	methods: {
		setActiveTab(id) {
			this.activeTab = id;
		},

		updateContentHeight() {
			if (
				this.$refs[`content-${this.activeTab}`] &&
				this.$refs[`content-${this.activeTab}`][0]
			) {
				const content = this.$refs[`content-${this.activeTab}`][0];
				const { height } = content.getBoundingClientRect();
				this.contentHeight = height;
			}
		},

		tabsNavigateBy(amount) {
			const tabList = [
				...this.$el.querySelectorAll('.c-subject-tab-list__tab'),
			];
			if (tabList.length > 1) {
				let currentFocus = this.$el.querySelector(
					'.c-subject-tab-list__tab:focus'
				);
				if (!currentFocus) {
					currentFocus = tabList[0];
				}
				const currentIndex = tabList.indexOf(currentFocus);
				let newIndex = currentIndex + amount;
				if (newIndex < 0) {
					newIndex = tabList.length - 1;
				} else if (newIndex >= tabList.length) {
					newIndex = 0;
				}

				tabList[newIndex].focus();
			}
		},

		// Mobile (accordion)
		openPanel(index) {
			if (this.$refs[`panel-${index}`]?.[0]?.open) {
				this.$refs[`panel-${index}`][0].open();
			}
		},
		onPanelChange(event, index) {
			this.$set(this.panelsOpen, index, event.isOpen);
		},
	},
};
</script>

<style>
.t-subject-tab-list__panel-enter-active,
.t-subject-tab-list__panel-leave-active {
	transition: max-height 300ms cubic-bezier(0.5, 0.035, 0.19, 1);
}

.t-subject-tab-list__panel-enter,
.t-subject-tab-list__panel-leave-to {
	max-height: 0;
}
</style>
