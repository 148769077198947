<!--
	Last modified: 2022/06/29 13:49:22
-->
<template functional>
	<svg
		v-if="props.svg"
		class="c-umbraco-svg"
		:class="{
			'c-umbraco-svg--colorize': !props.keepColors,
		}"
		v-bind="props.svg.attrs || {}"
		xmlns="http://www.w3.org/2000/svg"
		v-html="props.svg.content"
	/>
</template>

<script>
export default {
	name: 'UmbracoSvg',
	functional: true,

	props: {
		// The svg object containing .content and .attrs
		svg: {
			type: Object,
			default: null,
		},

		// Set to true to not overwrite colors
		keepColors: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style>
:where(.c-umbraco-svg--colorize[fill]:not([fill='none']):not([fill='transparent'])),
:where(.c-umbraco-svg--colorize
		[fill]:not([fill='none']):not([fill='transparent'])) {
	fill: currentColor;
}
:where(.c-umbraco-svg--colorize[stroke]:not([stroke='none']):not([stroke='transparent'])),
:where(.c-umbraco-svg--colorize
		[stroke]:not([stroke='none']):not([stroke='transparent'])) {
	stroke: currentColor;
}
</style>
