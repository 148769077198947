var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-subject-tab-list"},[_c('div',{staticClass:"<1024:hidden"},[_c('div',{staticClass:"flex bg-black",attrs:{"role":"tablist"}},_vm._l((_vm.items.slice(0, 5)),function(item,index){return _c('button',{key:`tab-${index}`,staticClass:"c-subject-tab-list__tab group w-1/4 text-left px-xl py-md",class:{
					'bg-background text-black': _vm.activeTab === item.id,
					'bg-black text-white': _vm.activeTab !== item.id,
				},attrs:{"id":`c-subject-tab-list__tab-${item.id}`,"role":"tab","aria-selected":_vm.activeTab === item.id ? 'true' : 'false',"aria-controls":`c-subject-tab-list__content-panel-${item.id}`,"tabindex":_vm.activeTab === item.id ? null : '-1'},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return (() => _vm.tabsNavigateBy(-1)).apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;return (() => _vm.tabsNavigateBy(1)).apply(null, arguments)}],"click":function($event){return _vm.setActiveTab(item.id)}}},[_c('span',{staticClass:"inline-flex flex-col justify-start items-start h-full"},[_c('div',{staticClass:"w-xl h-xl flex-shrink-0"},[_c('UmbracoSvg',{staticClass:"w-full h-full",attrs:{"svg":item.icon}})],1),_vm._v(" "),_c('div',{class:[
							'w-full transition-transform duration-300 ease-smooth-out mt-sm',
							'font-darker-grotesque font-semibold text-subject-tab',
							{
								'transform group-hover:translate-x-xs':
									_vm.activeTab !== item.id,
							},
						],domProps:{"innerHTML":_vm._s(item.title)}})])])}),0),_vm._v(" "),_c('div',{staticClass:"bg-background overflow-hidden"},[_c('div',{staticClass:"relative duration-200 ease-smooth-out",style:(`height: ${
					_vm.contentHeight != null ? _vm.contentHeight + 'px' : 'auto'
				}`)},[_c('TransitionExt',{attrs:{"is-group":"","name":"fade-in-out","duration":"200"},on:{"enter":_vm.updateContentHeight}},_vm._l((_vm.items),function(item,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.id === _vm.activeTab),expression:"item.id === activeTab"}],key:`tablist-${i}`,ref:`content-${item.id}`,refInFor:true,staticClass:"top-0 left-0 w-full pt-2xl pb-3xl px-xl",class:[
							item.id === _vm.activeTab ? 'relative' : 'absolute',
						],attrs:{"id":`c-subject-tab-list__content-panel-${item.id}`,"aria-hidden":item.id !== _vm.activeTab,"aria-labelledby":`c-subject-tab-list__tab-${item.id}`,"role":"tabpanel"}},[_c('div',{staticClass:"grid grid-cols-4 gap-x-3xl gap-y-xl"},_vm._l((item.children),function(child,j){return _c('LinkTile',{key:`link-${j}`,staticClass:"group",attrs:{"to":child.url,"target":child.hasExternalOutboundRedirect
										? '_blank'
										: child.target,"aria-labelledby":`subject-${i}-title-${j}`,"aria-describedby":child.teaser
										? `subject-${i}-description-${j}`
										: null}},[_c('div',{staticClass:"w-full font-darker-grotesque font-semibold text-subject-link transition-transform duration-500 ease-smooth-out transform group-hover:translate-x-8"},[_c('div',{staticClass:"inline-flex justify-center items-center w-24 h-24 -mt-14 -mb-10 mr-4 bg-black text-white rounded-full align-middle transition-transform duration-500 ease-smooth-out transform group-hover:-translate-x-8"},[_c('SvgArrowStout',{staticClass:"w-12"})],1),_vm._v(" "),_c('HeadingX',{staticClass:"inline",attrs:{"id":`subject-${i}-title-${j}`},domProps:{"innerHTML":_vm._s(child.title)}})],1),_vm._v(" "),(child.teaser)?_c('p',{staticClass:"text-teaser-sm mt-sm",attrs:{"id":`subject-${i}-description-${j}`},domProps:{"textContent":_vm._s(child.teaser)}}):_vm._e()])}),1)])}),0)],1)])]),_vm._v(" "),_c('AccordionGroup',{ref:"mobileAccordionGroup",staticClass:"overflow-hidden >=1024:hidden",attrs:{"max-one-open":true,"min-one-open":true}},[_vm._l((_vm.items),function(item,index){return [_c('AccordionHeader',{key:`header-${_vm._uid}-${index}`,class:[
					'relative flex justify-between items-center',
					'px-xl w-full group transition-colors duration-300',
					{
						'bg-background text-black': _vm.panelsOpen[index],
						'bg-black text-white': !_vm.panelsOpen[index],
					},
				],attrs:{"id":(item.id && `header-id${item.id}`) ||
					item.key ||
					`header-${_vm._uid}-${index}`,"aria-controls":`panel-${_vm._uid}-${index}`}},[_c('div',{staticClass:"flex justify-between flex-1 max-w-full py-lg",class:[
						{
							'border-t-2 -mt-2': index !== 0,
							'border-text':
								!_vm.panelsOpen[index] &&
								!_vm.panelsOpen[index - 1],
							'border-transparent': !(
								!_vm.panelsOpen[index] && !_vm.panelsOpen[index - 1]
							),
						},
					]},[_c('span',{staticClass:"flex-shrink-0 inline-flex justify-center items-center w-24 h-24 mr-lg"},[_c('div',{staticClass:"w-2xl h-2xl flex-shrink-0"},[_c('UmbracoSvg',{staticClass:"w-full h-full",attrs:{"svg":item.icon}})],1)]),_vm._v(" "),_c('div',{class:[
							'transform pr-lg mr-auto',
							'font-darker-grotesque font-semibold text-subject-tab',
							'transition-transform duration-500 ease-smooth-out',
							{
								'group-hover:translate-x-xs':
									!_vm.panelsOpen[index],
							},
						],domProps:{"innerHTML":_vm._s(item.title)}}),_vm._v(" "),_c('SvgCaret',{class:[
							'w-20 transform flex-shrink-0',
							'transition-all duration-300',
							{
								'-rotate-180 text-black': _vm.panelsOpen[index],
								'text-white': !_vm.panelsOpen[index],
							},
						]})],1)]),_vm._v(" "),_c('AccordionPanel',{key:`panel-${_vm._uid}-${index}`,ref:`panel-${index}`,refInFor:true,attrs:{"id":`panel-${_vm._uid}-${index}`,"transition":{ name: 't-subject-tab-list__panel' }},on:{"change":(event) => _vm.onPanelChange(event, index)}},[_c('div',{class:[
						'w-full flex flex-col items-start gap-lg',
						'px-xl py-lg transition-colors duration-300',
						{
							'bg-background text-black': _vm.panelsOpen[index],
							'bg-black text-white': !_vm.panelsOpen[index],
						},
					]},_vm._l((item.children),function(child,subindex){return _c('NuxtLinkExt',{key:subindex,staticClass:"group inline-block",attrs:{"to":child.url,"target":child.target}},[_c('div',{staticClass:"inline-flex items-center w-full font-darker-grotesque font-semibold text-subject-link transition-transform duration-500 ease-smooth-out transform group-hover:translate-x-8"},[_c('span',{staticClass:"flex-shrink-0 inline-flex justify-center items-center w-24 h-24 mr-lg bg-black text-white rounded-full align-middle transition-transform duration-500 ease-smooth-out transform group-hover:-translate-x-8"},[_c('SvgArrowStout',{staticClass:"w-12"})],1),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(child.title)}})])])}),1)])]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }