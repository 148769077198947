<template>
	<article class="d-subject-page-custom">
		<PageHeader
			v-bind="{
				...$attrs,
				...$props,
			}"
		/>

		<!-- Tabs -->
		<SubjectTabList
			v-if="items && items.length"
			class="mt-3xl"
			:items="items"
		/>

		<!-- Normal content -->
		<BlockList class="mt-5xl" :block-list="contentElements" />
		<FeedbackModule
			v-if="!hideFeedback"
			:feedback="feedback"
			class="mt-7xl"
		/>
	</article>
</template>

<script>
import PageHeader from '~/components/main/PageHeader';
import SubjectTabList from '~/components/main/SubjectTabList';
import BlockList from '~/components/main/BlockList';
import FeedbackModule from '~/components/shared/FeedbackModule';

export default {
	name: 'MainSubjectPageCustom',

	components: {
		PageHeader,
		SubjectTabList,
		BlockList,
		FeedbackModule,
	},

	inheritAttrs: false,

	props: {
		items: {
			type: Array,
			default: () => [],
		},
		contentElements: {
			type: Array,
			default: () => [],
		},
		hideFeedback: {
			type: Boolean,
			default: false,
		},
		feedback: Object,
	},
};
</script>
